// RunbookSelector.tsx
import React, { useState } from 'react';
import { Option, Select, OptionGroup } from "@leafygreen-ui/select";
import Banner from "@leafygreen-ui/banner";
import { Label, Description } from "@leafygreen-ui/typography";; // Adjust imports as needed

interface RunbookSelectorProps {
  selectedEngagement: string;
  setRunbook: (value: string) => void;
}

const runbookDescriptions: { [key: string]: string } = {
  "Atlas Metrics Review": "Analyzing our customer’s Atlas cluster performance and health. Detect and explain issues, and provide recommendations. Not a discovery call.",
  "Backup optimisations": "DEvaluating and suggesting changes in backup policy to help our customers control their spending on backups and to understand where the cost is coming from",
  // Add more descriptions as needed
};

const RunbookSelector: React.FC<RunbookSelectorProps> = ({ selectedEngagement, setRunbook }) => {
  const [runbook, setLocalRunbook] = useState<string>("");

  const handleRunbookChange = (selectedValue: string) => {
    setLocalRunbook(selectedValue);
    setRunbook(selectedValue); // Propagate the change to parent component
  };

  if (selectedEngagement !== "runbooks") return null;

  return (
    <>
      <Label className="fieldMargin" id="description-label" htmlFor="descriptionTextArea">
        Runbook *
      </Label>
      <Description>
        Find detailed explanations for our engagements on{' '}
        <a
          href="https://wiki.corp.mongodb.com/display/10GEN/CS+x+RSC%3A+Runbook+Master+list"
          target="_blank"
          rel="noopener noreferrer"
        >
          this page
        </a>.
      </Description>
      <Select
        className="fieldMargin"
        label=""
        name="runbook"
        baseFontSize={13}
        onChange={(selectedValue) => handleRunbookChange(selectedValue)}
      >
        <OptionGroup label="CONSUMPTION">
          <Option value="Atlas Metrics Review">Atlas Metrics Review</Option>
          <Option value="Backup optimisations">Backup optimisations</Option>
        </OptionGroup>
        {/* Uncomment and add more groups/options as needed */}
        {/* <OptionGroup label="ONBOARD">
          <Option value="Technical Readiness Review">Technical Readiness Review</Option>
          <Option value="Technical Success Plan">Technical Success Plan</Option>
          <Option value="Support post go-live lookback">Support post go-live lookback</Option>
          <Option value="Pre-prod Design Validation">Pre-prod Design Validation</Option>
          <Option value="Support go-live">Support go-live</Option>
        </OptionGroup> */}
      </Select>
      {runbook && (
        <Banner variant="info" className="bannerStyle fieldMargin">
          <strong>{runbook}</strong>
          <br />
          <br />
          {runbookDescriptions[runbook]}
        </Banner>
      )}
    </>
  );
};

export default RunbookSelector;